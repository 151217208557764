import { Button, Form, Input, Select } from 'antd'

import { editBlackSVG } from 'assets/svg/edit'
import { EditButtonRowWrapper, EditButtonWrapper } from 'atom/tenants'
import { capitalizeAndReplaceUnderscore } from 'helpers/users'
import { useGeneralTranslation } from 'hooks/translation/useGeneralTranslation'
import { useTenantTranslation } from 'hooks/translation/useTenantTranslation'
import { TenantRef } from 'models/tenant'
import { useIntl } from 'react-intl'
import { useAppState } from 'state'

interface TenantBasicInfoProps {
  tenant: TenantRef
  disabled: boolean
  status?: string
  onEditTenantStatusModal: (value: boolean) => void
}
const TenantBasicInfo: React.FC<TenantBasicInfoProps> = ({ tenant, disabled, status, onEditTenantStatusModal }) => {
  const { currentUser, IsTablet, IsMobile } = useAppState()
  const isTablet = IsTablet()
  const isMobile = IsMobile()
  const {
    editStatusText,
    tenantStatusText,
    siteNameText,
    networkPackageText,
    networkPackageManagementText,
    networkPackageMonitoringText,
    networkPackageLiteText,
    networkPackageEssentialText,
    networkPackagePremiumText,
    networkPackageEnterpriseText,
  } = useTenantTranslation()
  const { displayNameText } = useGeneralTranslation()

  const intl = useIntl()

  const normalizeTenantStatus = (status?: string) => {
    if (!status) {
      return
    }
    if (status === 'pending_sa') {
      return 'Pending SA'
    }

    const translateWord = intl.formatMessage({ id: `dashboard.text.${status}` })

    return capitalizeAndReplaceUnderscore(translateWord)
  }
  const networkPackageOptions = [
    { value: 'network_management', label: networkPackageManagementText },
    { value: 'energy_monitoring', label: networkPackageMonitoringText },
    { value: 'lite', label: networkPackageLiteText },
    { value: 'essential', label: networkPackageEssentialText },
    { value: 'premium', label: networkPackagePremiumText },
    { value: 'enterprise', label: networkPackageEnterpriseText },
  ]

  return (
    <>
      <Form.Item id="ocppName" label={siteNameText}>
        <span className="ant-form-text">{tenant?.name}</span>
      </Form.Item>

      <Form.Item name="displayName" label={displayNameText} className="align-center">
        <Input disabled={disabled} />
      </Form.Item>

      <EditButtonRowWrapper isTablet={isTablet} isMobile={isMobile} spaceBetween="-13.5">
        <Form.Item id="status" label={tenantStatusText} className="align-center">
          <Input
            disabled={true}
            placeholder="Set the status of the tenant"
            defaultValue={tenant.status ? normalizeTenantStatus(tenant.status) : tenant.status}
            value={status ? normalizeTenantStatus(status) : status}
          />

          {isMobile || isTablet ? (
            <EditButtonWrapper>
              <Button
                type="primary"
                disabled={disabled}
                icon={editBlackSVG}
                onClick={() => onEditTenantStatusModal(true)}
              />
            </EditButtonWrapper>
          ) : (
            <Button
              type="primary"
              disabled={currentUser?.role !== 'admin' || disabled}
              onClick={() => onEditTenantStatusModal(true)}
              style={{ justifyContent: 'flex-start', alignContent: 'center' }}
            >
              {editStatusText}
            </Button>
          )}
        </Form.Item>
        <Form.Item
          name="networkPackage"
          label={networkPackageText}
          rules={[{ required: true }]}
          className="label-with-textinput-error"
        >
          <Select disabled={disabled} allowClear>
            {networkPackageOptions.map((option) => (
              <Select.Option key={option.value} value={option.value} label={option.label}>
                {option.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </EditButtonRowWrapper>
    </>
  )
}

export default TenantBasicInfo
