import { Button, Form, Modal, Select } from 'antd'
import { useGeneralTranslation } from 'hooks/translation/useGeneralTranslation'
import { useNotifications } from 'hooks/useNotification'
import { useSelector } from 'hooks/useSelector'
import { AccessPlan, AccessPlanPayload } from 'models/access-plan'
import { DiscountSchema } from 'models/discount'
import { LocalDiscount } from 'models/price'
import { useEffect, useState } from 'react'
import { GetAllAccessPlans } from 'services/data-provider/access-plan'
import { ActiveDiscountForUser, AddUsersToDiscounts, RemoveUsersFromDiscounts } from 'services/data-provider/discount'
import { StyledDrawer } from '../../atom/drawer'
import { HorizontalLine } from '../../atom/horizontal-line'
import { ButtonTextSpacing, FooterLayout, Heading } from '../../atom/user-edit'
import { MobileLineContainer } from '../../atom/user-invite'
import { AlertError } from '../../components/error'
import { capitalizeFirstLetter } from '../../helpers/users'
import { useUserTranslation } from '../../hooks/translation/useUserTranslation'
import { SwtchError } from '../../models/error'
import { AugmentedUser, UserPermissions, UserRole, userRole } from '../../models/user'
import { NewUpdateUser } from '../../services/data-provider/users'
import { useAppState } from '../../state'
import UserBasicInfo from './modal/UserBasicInfo'
import { MemoizedEditForm } from './modal/user-edit-form'

interface props {
  selectedUser: AugmentedUser
  onCancel: (e: React.FormEvent<EventTarget>) => void
  onUserEdit: () => void
}

export interface AccessPlanObj {
  [key: string]: AccessPlan[]
}

export const UserEditForm: React.FC<props> = ({ onCancel, selectedUser, onUserEdit }) => {
  const [form] = Form.useForm()
  const { siteLabelCleaner } = useSelector()
  const [error, setError] = useState<SwtchError>()
  const [loading, setLoading] = useState(false)
  const [userPermissions, setUserPermissions] = useState<UserPermissions[]>([])
  const [initialUserPermissions, setInitialUserPermissions] = useState<UserPermissions[]>([])
  const [name, setName] = useState(selectedUser.name)
  const [note, setNote] = useState(selectedUser.note)
  const [selectedUserRole, setSelectedUserRole] = useState<UserRole>()
  const [disableSave, setDisableSave] = useState(true)
  const { editUserText, saveText, cancelText, updateText } = useUserTranslation()
  const { currentUser, IsMobile, isMockUpEnabled } = useAppState()
  const isMobile = IsMobile()
  const [idsWithOperatorRole, setIdsWithOperatorRole] = useState<string[]>([]) //id with operator role
  const [allAccessPlans, setAllAccessPlans] = useState<AccessPlanObj>({})
  const [existingDiscounts, setExistingDiscounts] = useState<LocalDiscount[]>([])
  const { openErrorNotification, openSuccessNotification } = useNotifications()
  const { somethingWentWrongText } = useGeneralTranslation()
  const permissionTransform = {
    'tenant.none': 'none',
    'tenant.viewer': 'viewer',
    'tenant.manager': 'manager',
    'tenant.charger_operator': currentUser?.role === 'admin' ? 'charger_operator' : 'manager',
  }

  const determineUserRole = () => {
    if (selectedUser.isAdmin) return 'admin'
    if (selectedUser.isSupport) return 'support'
    if (selectedUser.isUser) return 'user'
  }

  const fetchActiveDiscount = async () => {
    try {
      const currentDiscount = await ActiveDiscountForUser(selectedUser.email, isMockUpEnabled)
      const parsedDiscount = currentDiscount.map(({ id, name }) => ({ id, name }))
      setExistingDiscounts(parsedDiscount)
      await handleUserAccess(currentDiscount)
    } catch (err) {
      openErrorNotification((err as SwtchError).description || somethingWentWrongText)
    } finally {
      setLoading(false)
    }
  }

  const handleUserAccess = async (tenantDiscount: DiscountSchema[]) => {
    let objArr: any[] = []
    let idWithOperatorRole: string[] = []

    let updatedUserPermissions: any[] = []
    const currentAccess = selectedUser.accesses.map((access) => ({ ...access, combineName: 'SWTCH HQ (swtchhq)' }))
    currentAccess.forEach((access) => {
      if (access.resourceType !== 'Tenant') return

      let driver = false
      let roles = 'none'

      const tenantId = access.resourceId
      access.permissions.forEach((permission) => {
        if (permission === 'tenant.driver') {
          driver = true
        } else {
          roles = getUpdatedRole(permission, roles) // Update roles with permissions
        }
        if (permission === 'tenant.charger_operator') {
          idWithOperatorRole.push(tenantId)
        }
      })
      const tenantName =
        currentUser?.role === 'admin'
          ? siteLabelCleaner(`${access.displayName ? `${access.displayName} ` : ''}(${access.display || ''})`)
          : siteLabelCleaner(access.displayName || access.display)
      const accessPlansForTenant = selectedUser.accessPlans.filter((plan) => plan.tenant_id === Number(tenantId))

      updatedUserPermissions.push({
        name: tenantName,
        id: tenantId,
        access: roles,
        driver: driver,
        accessPlan: [...accessPlansForTenant],
        portal: roles !== 'none',
        driverOption: accessPlansForTenant.length > 0,
      })
    })
    //Populate discountPlan
    tenantDiscount.forEach((discount) => {
      const discountTenantId = discount.tenantId
      const existingTenantIndex = updatedUserPermissions.findIndex((obj: any) => obj.id === discountTenantId)
      if (existingTenantIndex > -1) {
        const currentDiscountPlan = [...(updatedUserPermissions[existingTenantIndex]?.discountPlan ?? []), discount]
        updatedUserPermissions[existingTenantIndex] = {
          ...updatedUserPermissions[existingTenantIndex],
          discountPlan: currentDiscountPlan,
          driverOption: updatedUserPermissions[existingTenantIndex]?.driverOption || currentDiscountPlan.length > 0,
        }
      }
    })

    // Ensure all access plans are accounted for
    selectedUser.accessPlans.forEach((accessPlan) => {
      const accessPlanTenantId = accessPlan.tenant_id
      const existingTenantIndex = updatedUserPermissions.findIndex((obj: any) => obj.id === accessPlanTenantId)
      if (existingTenantIndex > -1) {
        const alreadyExists = updatedUserPermissions[existingTenantIndex].accessPlan.some(
          (plan: AccessPlanPayload) => plan.id === accessPlan.id,
        )
        if (!alreadyExists) objArr[existingTenantIndex].accessPlan.push(accessPlan)
      }
    })

    // Final state updates
    setIdsWithOperatorRole(idWithOperatorRole)
    setUserPermissions(updatedUserPermissions)
    setInitialUserPermissions(updatedUserPermissions)
    setLoading(false)
  }

  const fetchAllAccessPlansForTenants = async () => {
    const currentUserTenantAccesses = currentUser?.accesses
      ?.filter(({ resourceType, permissions }) => resourceType === 'Tenant' && permissions?.length)
      .map(({ resourceId }) => Number(resourceId))

    const uniqueTenantIds = Array.from(
      new Set([
        ...(selectedUser?.accesses || [])
          .map(({ resourceId, resourceType }) => (resourceType === 'Tenant' ? resourceId : ''))
          .filter((val) => !!val),
        ...(selectedUser?.accessPlans || []).map(({ tenant_id }) => tenant_id),
      ]),
    ).filter((tenantId) => {
      if (currentUser?.role === 'admin') return true
      else return currentUserTenantAccesses?.includes(tenantId as number)
    })
    try {
      // Fetch all access plans concurrently
      const responses = await Promise.all(
        uniqueTenantIds.map(async (tenantId) => {
          const resp = await GetAllAccessPlans(isMockUpEnabled, Number(tenantId))
          return { tenantId, resp }
        }),
      )
      // Filter out responses with errors and create the new state object
      const accessPlans = responses.reduce<AccessPlanObj>((acc, { tenantId, resp }) => {
        if (!('error' in resp)) {
          acc[tenantId] = resp
        }
        return acc
      }, {})
      // Update state once with the combined access plans
      setAllAccessPlans((prev) => ({ ...prev, ...accessPlans }))
    } catch (error) {
      console.error('Failed to fetch access plans:', error)
    }
  }

  useEffect(() => {
    if (!selectedUser) return
    setLoading(true)
    setSelectedUserRole(determineUserRole())
    fetchAllAccessPlansForTenants()
    fetchActiveDiscount()
  }, [selectedUser])

  useEffect(() => {
    const role = selectedUser.isAdmin ? 'admin' : selectedUser.isSupport ? 'support' : selectedUser.isUser ? 'user' : ''

    const canClickSaveButton =
      name !== selectedUser.name ||
      note !== selectedUser.note ||
      role !== selectedUserRole ||
      //There has been changes in the user permissions and the user has at least one tenant with role or access plan
      (JSON.stringify(userPermissions) !== JSON.stringify(initialUserPermissions) &&
        (userPermissions.length === 0 ||
          (userPermissions.length > 0 &&
            userPermissions.every(
              (permission) =>
                permission.id &&
                permission.name &&
                ((permission.portal && permission.access) ||
                  (permission.driverOption && (permission.accessPlan?.length || permission.discountPlan?.length))),
            ))))

    setDisableSave(!canClickSaveButton)
  }, [name, note, selectedUserRole, userPermissions, initialUserPermissions])

  // Helper function to update roles based on permissions
  const getUpdatedRole = (permission: string, currentRole: string): string => {
    const role = permissionTransform[permission as keyof typeof permissionTransform]
    if (currentRole === 'none') return role
    if (currentRole === 'viewer' && role !== 'none') return role
    if (currentRole === 'manager' && role !== 'none' && role !== 'viewer') return role
    return currentRole
  }

  const handleUserRoleChange = (role: UserRole) => {
    setSelectedUserRole(role)
  }

  const afterClose = () => {
    form.resetFields()
  }
  const onOk = () => {
    let updateName = ''
    let updateNote = ''
    if (selectedUser.name !== name) updateName = name
    if (selectedUser.note !== note) updateNote = note
    const userToUpdate = {
      id: selectedUser.id,
      email: selectedUser.email,
      name: updateName,
      note: updateNote,
      role: selectedUserRole,
    }

    setLoading(true)
    const updatedUserPermissions: UserPermissions[] = [...userPermissions].map((user) => ({
      ...user,
      access: user.portal ? user?.access : 'none',
      accessPlan: user.driverOption ? user?.accessPlan : [],
      discountPlan: user.driverOption ? user?.discountPlan : [],
      driverOption: undefined,
      portal: undefined,
    }))

    const updatedDiscounts: LocalDiscount[] = []

    userPermissions.forEach((user, index) => {
      //If the user is not admin and didn't update the tenant with operator role
      if (currentUser?.role !== 'admin' && idsWithOperatorRole.includes(user.id) && user.access === 'manager') {
        updatedUserPermissions.splice(index, 1, { ...user, access: 'charger_operator' })
      }

      user.discountPlan?.forEach((discount) => {
        updatedDiscounts.push({ id: discount.id, name: discount.name })
      })
    })

    const currentUserArray = [{ email: selectedUser?.email || '' }]

    if (existingDiscounts.length && updatedDiscounts.length) {
      RemoveUsersFromDiscounts(isMockUpEnabled, existingDiscounts, currentUserArray)
        .then((res) => {
          AddUsersToDiscounts(isMockUpEnabled, updatedDiscounts, currentUserArray)
            .then((res) => console.log('res', res))
            .catch((err) => setError(err))
        })
        .catch((err) => setError(err))
    } else if (existingDiscounts.length) {
      RemoveUsersFromDiscounts(isMockUpEnabled, existingDiscounts, currentUserArray)
        .then((res) => console.log('res', res))
        .catch((err) => setError(err))
    } else if (updatedDiscounts.length) {
      AddUsersToDiscounts(isMockUpEnabled, updatedDiscounts, currentUserArray)
        .then((res) => console.log('res', res))
        .catch((err) => setError(err))
    }

    const accessPlanIds = updatedUserPermissions
      .flatMap((item) => item.accessPlan?.map((plan) => plan.id))
      .filter((id): id is number => id !== undefined)

    NewUpdateUser(isMockUpEnabled, updatedUserPermissions, accessPlanIds, userToUpdate)
      .then((resp) => {
        openSuccessNotification('Updated info')
        return onUserEdit()
      })
      .catch((err) => setError(err))
      .finally(() => setLoading(false))
  }

  return (
    <>
      <AlertError error={error} />
      {!isMobile ? (
        <Modal
          title={<Heading>{editUserText}</Heading>}
          closable={true}
          okText={updateText}
          cancelText={cancelText}
          visible={true}
          onCancel={onCancel}
          onOk={onOk}
          afterClose={afterClose}
          width={543}
          className="fixed-row-modal"
          footer={
            <FooterLayout>
              <Button style={{ width: '240px' }} loading={loading} onClick={onCancel}>
                <span className="paragraph-02-regular">{cancelText}</span>
              </Button>
              <Button style={{ width: '240px' }} type="primary" loading={loading} onClick={onOk} disabled={disableSave}>
                <ButtonTextSpacing disabled={disableSave}>
                  <span className="paragraph-02-regular">{saveText}</span>
                </ButtonTextSpacing>
              </Button>
            </FooterLayout>
          }
        >
          {currentUser?.role === 'admin' && (
            <>
              <Select
                style={{ width: '100%' }}
                value={selectedUserRole}
                onChange={(value) => handleUserRoleChange(value)}
              >
                {userRole.map((role) => (
                  <Select.Option value={role}>{capitalizeFirstLetter(role)}</Select.Option>
                ))}
              </Select>
              <HorizontalLine />
            </>
          )}
          <UserBasicInfo editUser={selectedUser} note={note} name={name} setName={setName} setNote={setNote} />
          <MemoizedEditForm
            userPermissions={userPermissions}
            loading={loading}
            allAccessPlans={allAccessPlans}
            setUserPermissions={setUserPermissions}
            setAllAccessPlans={setAllAccessPlans}
          />
        </Modal>
      ) : (
        <StyledDrawer
          placement="bottom"
          closable={false}
          onClose={onCancel}
          visible={true}
          key="bottom"
          footer={
            <Button style={{ width: '100%' }} type="primary" loading={loading} onClick={onOk} disabled={disableSave}>
              <ButtonTextSpacing disabled={disableSave}>
                <span className="paragraph-02-regular">{saveText}</span>
              </ButtonTextSpacing>
            </Button>
          }
          footerStyle={{ boxShadow: '0px -3px 5px 0px rgba(0, 0, 0, 0.06)', padding: '25px 15px' }}
        >
          <MobileLineContainer />
          <Heading>{editUserText}</Heading>
          <HorizontalLine />
          {currentUser?.role === 'admin' && (
            <>
              <Select
                style={{ width: '100%' }}
                value={selectedUserRole}
                onChange={(value) => handleUserRoleChange(value)}
              >
                {userRole.map((role) => (
                  <Select.Option value={role}>{capitalizeFirstLetter(role)}</Select.Option>
                ))}
              </Select>
              <HorizontalLine />
            </>
          )}
          <UserBasicInfo editUser={selectedUser} note={note} name={name} setName={setName} setNote={setNote} />
          <MemoizedEditForm
            userPermissions={userPermissions}
            loading={loading}
            allAccessPlans={allAccessPlans}
            setUserPermissions={setUserPermissions}
            setAllAccessPlans={setAllAccessPlans}
          />
        </StyledDrawer>
      )}
    </>
  )
}
