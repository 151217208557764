import { Button, Checkbox, Col, Input, Row, Select, Space, message } from 'antd'
import { CloseCircleFilled } from '@ant-design/icons'
import { debounce } from 'lodash'
import { Moment } from 'moment'
import { TransactionFilter } from '../../../../models/filter'
import { TenantRef } from '../../../../models/tenant'
import { LineBreak } from '../../../../atom/line-break'
import { ButtonIcon } from '../../../../atom/button'
import { SingleSelectorWrapper } from '../../../../atom/input'
import { magnifyBlackSVG } from '../../../../assets/svg/magnify'
import { useContactTranslation } from '../../../../hooks/translation/useContactTranslation'
import { useTransactionSearchTranslation } from '../../../../hooks/translation/useTransactionSearchTranslation'
import { useAppState } from '../../../../state'
import { useGeneralTranslation } from '../../../../hooks/translation/useGeneralTranslation'
import { CustomDateRangePicker } from '../../../../components/redesign/datepicker'
import { useCalendarTransactions } from '../../../../hooks/translation/useCalendarTranslation'
import { isValidEmail } from '../../../../helpers/regularExpression/email'
import { ReusableSelector } from 'components/reusableSelector/selector'
import { useSelectorTranslation } from 'hooks/translation/useSelectorTranslation'
import { useListingBasicTrans } from 'hooks/translation/useListingBasicTrans'
import { ReactElement } from 'react'
import { useSelector } from 'hooks/useSelector'
import { MasterAccountRef } from 'models/master-account'
import queryString from 'query-string'
import { useLocation } from 'react-router-dom'

interface props {
  loading: boolean
  tenants: TenantRef[]
  defaultTenant?: TenantRef
  filter: TransactionFilter
  startDate: Moment
  endDate: Moment
  disabled: boolean
  dropdownClassName?: string
  reset: boolean
  onFilterChange: (filter: TransactionFilter) => void
  onChangeDate: (dates: Moment[]) => void
  onTenants: (tenants: TenantRef[]) => void
  onFilter: (filters: TransactionFilter) => void
  onTenantSelection: (tenants: TenantRef[]) => void
  onFetchTransactions: () => void
  onEmail: (email: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  onTransactionId: (transactionId: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  onDisabled: (isDisabled: boolean) => void
  checkValidEmail: (email?: string) => void
  onFetchDataWithQuery: () => void
}

const TransactionsSelectors: React.FC<props> = ({
  tenants,
  defaultTenant,
  filter,
  startDate,
  endDate,
  disabled,
  dropdownClassName,
  reset,
  onFilterChange,
  onChangeDate,
  onTenants,
  onFilter,
  onTenantSelection,
  onEmail,
  onTransactionId,
  onFetchTransactions,
  onDisabled,
  checkValidEmail,
  onFetchDataWithQuery,
  ...props
}) => {
  const { IsDesktop, IsLaptop, isMockUpEnabled } = useAppState()
  const location = useLocation()
  const { transaction_id } = queryString.parse(location.search)
  const isDesktop = IsDesktop()
  const isLaptop = IsLaptop()
  const { emailText } = useContactTranslation()
  const { transactionIDText } = useTransactionSearchTranslation()
  const { searchText } = useGeneralTranslation()
  const { calendarRanges } = useCalendarTransactions()
  const { accountNamesText, locationTextPlaceholder } = useSelectorTranslation()
  const { listingTitlesText } = useListingBasicTrans()

  const {
    multiTenantDebounceFetcher,
    multiListingTitleDebounceFetcher,
    multiTenantHandleChange,
    multiListingTitleHandleChange,
    multiListingTitleHandlePlaceholder,
    multiTenantHandlePlaceholder,
    multiTenantHandleValue,
    multiListingTitleHandleValue,
    renderMultiTenantOption,
    multiMasterAccountDebounceFetcher,
    multiMasterAccountHandleChange,
    multiMasterAccountHandlePlaceholder,
    multiMasterAccountHandleValue,
    renderMultiMasterAccountOption,
  } = useSelector()

  const handleTransactionIdChange = (transactionId?: number | string) => {
    if (!transactionId) {
      return
    }

    return handleOnPressEnter()
  }

  const handleOnPressEnter = () => {
    return onFetchTransactions()
  }

  const handleEnterKeyPress = (email?: string) => {
    message.destroy()
    if (!email) {
      onDisabled(false)
      return
    }
    if (isValidEmail(email.trim())) {
      onDisabled(false)
      handleOnPressEnter()
      return
    } else {
      onDisabled(true)
      message.error('Invalid email')
    }
  }

  const handleMasterAccountSelection = (masterAccounts: MasterAccountRef[]) =>
    onFilterChange({
      masterAccounts: masterAccounts.filter((obj, index) => {
        return index === masterAccounts.findIndex((o) => obj.id === o.id)
      }),
    })

  const renderMultiTenantSelector = (
    <ReusableSelector
      showSearch
      onOptionsChange={debounce((tenants) => onTenantSelection(tenants), 1000)}
      onClear={() => onTenants([])}
      combineType="right"
      customWidth="220px"
      isSingle={false}
      placeholder={locationTextPlaceholder}
      dropDownList={tenants}
      showMagnifySVG={true}
      maxTagCount={0}
      isDebounceFetcher={true}
      debounceFetcher={multiTenantDebounceFetcher}
      reset={reset}
      showArrow={false}
      handlePlaceholder={multiTenantHandlePlaceholder}
      handleOptionChange={multiTenantHandleChange}
      handleValue={multiTenantHandleValue}
      renderOption={renderMultiTenantOption}
      isMockUpEnabled={isMockUpEnabled}
    />
  )

  const renderMasterAccountSelector = (
    <ReusableSelector
      maxTagCount={0}
      showArrow={false}
      showSearch={true}
      isSingle={false}
      showMagnifySVG={false}
      isDebounceFetcher={true}
      combineType="both"
      fieldForValue="id"
      placeholder={accountNamesText}
      dropdownClassName={dropdownClassName}
      onClear={() => onFilterChange({ ...filter, masterAccounts: [] })}
      onOptionsChange={(opts: MasterAccountRef[]) => handleMasterAccountSelection(opts)}
      debounceFetcher={multiMasterAccountDebounceFetcher}
      handlePlaceholder={multiMasterAccountHandlePlaceholder}
      handleValue={multiMasterAccountHandleValue}
      handleOptionChange={multiMasterAccountHandleChange}
      renderOption={renderMultiMasterAccountOption}
      isMockUpEnabled={isMockUpEnabled}
    />
  )

  const renderMultiListingTitleOption = (
    option: any,
    selectedOptions: any[],
    handleCheckboxClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void,
  ): ReactElement => {
    return (
      <Select.Option key={option.id} value={option.title} label={option.title}>
        <Checkbox
          onClick={handleCheckboxClick}
          checked={selectedOptions.findIndex((listing) => listing.title === option.title) > -1}
        >
          {option.title}
        </Checkbox>
      </Select.Option>
    )
  }

  const renderMultiListingTitleSelector = (
    <ReusableSelector
      showSearch
      dropdownClassName={dropdownClassName}
      onOptionsChange={(listingTitle: string[]) => onFilterChange({ ...filter, listingTitle })}
      onClear={() => onTenants([])}
      combineType="both"
      isSingle={false}
      placeholder={listingTitlesText}
      dropDownList={tenants}
      showMagnifySVG={false}
      maxTagCount={0}
      isDebounceFetcher={true}
      filter={filter}
      reset={reset}
      debounceFetcher={multiListingTitleDebounceFetcher}
      showArrow={false}
      handlePlaceholder={multiListingTitleHandlePlaceholder}
      handleOptionChange={multiListingTitleHandleChange}
      handleValue={multiListingTitleHandleValue}
      renderOption={renderMultiListingTitleOption}
      isMockUpEnabled={isMockUpEnabled}
    />
  )

  const renderEmailSelector = (
    <SingleSelectorWrapper circularBorder isDesktop={isDesktop} isLaptop={isLaptop}>
      <Input
        placeholder={emailText}
        onChange={onEmail}
        prefix={<></>}
        style={{ minWidth: '213px', width: '100%', lineHeight: '26px' }}
        value={filter.email}
        type="email"
        allowClear={{ clearIcon: <CloseCircleFilled onClick={() => checkValidEmail()} /> }}
        onPressEnter={() => handleEnterKeyPress(filter.email)}
      />
    </SingleSelectorWrapper>
  )

  const renderTransactionIdSelector = (
    <SingleSelectorWrapper isCombineRight isDesktop={isDesktop} isLaptop={isLaptop}>
      <Input
        type="number"
        defaultValue={transaction_id as string | undefined}
        placeholder={transactionIDText}
        onChange={onTransactionId}
        prefix={<></>}
        style={{ width: '100%', lineHeight: '26px' }}
        value={filter.transactionId}
        onPressEnter={() => handleTransactionIdChange(filter.transactionId)}
      />
    </SingleSelectorWrapper>
  )

  const calendar = (
    <CustomDateRangePicker
      startDate={startDate}
      endDate={endDate}
      onChangeDate={onChangeDate}
      calendarRanges={calendarRanges}
      disabled={props.loading}
    />
  )

  return (
    <>
      {isDesktop || isLaptop ? (
        <Space wrap size={[0, 6]}>
          {calendar}
          <div style={{ marginRight: '1rem' }}></div>
          {renderMultiTenantSelector}
          <LineBreak />
          {renderMasterAccountSelector}
          <LineBreak />
          {renderMultiListingTitleSelector}
          <LineBreak />
          {renderEmailSelector}
          <LineBreak />
          {renderTransactionIdSelector}
          <ButtonIcon
            icon={magnifyBlackSVG}
            loading={props.loading}
            disabled={disabled}
            fetchData={onFetchDataWithQuery}
            withinSearchBar
          />
        </Space>
      ) : (
        <Row gutter={[8, 8]}>
          <Col xs={24} md={12}>
            {renderMultiTenantSelector}
          </Col>
          <Col xs={24} md={12}>
            {renderMultiListingTitleSelector}
          </Col>
          <Col xs={24} md={12}>
            {renderEmailSelector}
          </Col>
          <Col xs={24} md={12}>
            {renderTransactionIdSelector}
          </Col>
          <Col xs={24} md={12}>
            {calendar}
          </Col>
          <Button
            block
            type="primary"
            loading={props.loading}
            disabled={disabled}
            onClick={onFetchTransactions}
            style={{ margin: '0 5px' }}
          >
            {searchText}
          </Button>
        </Row>
      )}
    </>
  )
}

export default TransactionsSelectors
