import { renderFormatMessage, useFormatMessage } from '../../helpers/intl'

export const useListingPosTerminalTranslation = () => {

  const posTerminalText = renderFormatMessage('dashboard.text.posTerminal', 'POS Terminal')
  const terminalText = renderFormatMessage('dashboard.text.posTerminal', 'Terminal:')

  const posNoneTerminalText = renderFormatMessage('dashboard.text.posTerminal.none', 'None')
  const posNayaxTerminalText = renderFormatMessage('dashboard.text.posTerminal.nayax', 'Nayax')
  const posPayterTerminalText = renderFormatMessage('dashboard.text.posTerminal.payter', 'Payter')
  const nayaxLableText = renderFormatMessage('dashboard.text.posTerminal.nayax.label', 'Nayax Serial Number')
  const nayaxPlaceholderText = useFormatMessage(
    'dashboard.text.posTerminal.nayax.placeholder',
    'Example: 4434330823183186',
  )
  const nayaxValidationText = renderFormatMessage('dashboard.text.posTerminal.nayax.validationMessage', 'Please enter the Nayax serial number')
  const payterLableText = renderFormatMessage('dashboard.text.posTerminal.payter.label', 'Payter Serial Number')
  const payterValidationText = renderFormatMessage('dashboard.text.posTerminal.payter.validationMessage', 'Please enter the Payter serial number')
  const payterPlaceholderText = useFormatMessage(
    'dashboard.text.posTerminal.payter.placeholder',
    'Example: APM20224205211',
  )
  return {
    terminalText,
    posTerminalText,
    posNoneTerminalText,
    posNayaxTerminalText,
    posPayterTerminalText,
    nayaxLableText,
    nayaxValidationText,
    nayaxPlaceholderText,
    payterLableText,
    payterValidationText,
    payterPlaceholderText
  }
}
