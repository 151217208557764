import { List, Typography } from 'antd'
import { Transaction } from 'models/transaction'

import { DateTimeV2 } from 'components/date-time'
import { AlignedContentRight } from 'components/wrapper'
import { TransactionStateBadge } from 'components/transactions/transaction-state-badge'

import { useConnectorTranslation } from 'hooks/translation/useConnectorTranslation'
import { useTransactionMetricTranslation } from 'hooks/translation/useTransactionMetricTranslation'
import { useTransactionSearchTranslation } from 'hooks/translation/useTransactionSearchTranslation'
import { useCarTranslation } from 'hooks/translation/useCarTranslation'
import { useChargerTranslation } from 'hooks/translation/useChargerTranslation'
import { useGeneralTranslation } from 'hooks/translation/useGeneralTranslation'
import { useListingBasicTrans } from 'hooks/translation/useListingBasicTrans'

import { renderChargingStop } from 'helpers/date-format'
import { formatDurationWithTwoDigits } from 'helpers/duration'
import { formatEnergy } from 'helpers/number-format'
import { theme } from 'theme'
import { useSelector } from 'hooks/useSelector'
import { useAppState } from 'state'
import configProvider from 'config'
import { renderFormatMessage } from 'helpers/intl'

interface props {
  transaction: Transaction
}

interface ListProps {
  property: string
  value: any
  key: string
  description?: any
  className?: string
}

const TransactionPanelItem: React.FC<props> = ({ transaction }) => {
  const { listingTitleText, listingIDText } = useListingBasicTrans()
  const { chargerTypeText, SNText, vendorText } = useChargerTranslation()
  const { connectorTypeText, connectorIdText } = useConnectorTranslation()

  const { brandText, modelText, socAfterChargingText, socBeforeChargingText, yearText } = useCarTranslation()
  const { totalEnergyText } = useTransactionMetricTranslation()
  const { stateText, startMethodText, rfidNumberText } = useTransactionSearchTranslation()
  const {
    connectedText,
    disconnectedText,
    chargingStartText,
    chargingEndText,
    chargingDurationText,
    tenantText,
  } = useGeneralTranslation()
  const { siteLabelCleaner } = useSelector()
  const { currentUser } = useAppState()
  const {
    listing,
    charger,
    user,
    connector,
    car,
    percentStart,
    percent,
    startMethod,
    rfidNumber,
    startAt,
    endAt,
    tenant,
    chargeStartAt,
    chargeEndAt,
    timezone,
    currentChargingDuration,
    energy,
  } = transaction

  const ellipsisValue = (value: string): JSX.Element | string => {
    if (value.length < 25) {
      return value
    }
    return (
      <Typography.Text style={{ color: theme.colors.darkGray, width: 140 }} ellipsis={{ tooltip: value }}>
        {value}
      </Typography.Text>
    )
  }

  const doesDisplayNameExist = siteLabelCleaner(tenant.combineName)

  const listingInfo = [
    {
      property: startMethodText,
      value: renderFormatMessage(
        `dashboard.transactionsPage.advancedSearch.startMethod.${startMethod.toLowerCase()}`,
        startMethod.toLowerCase(),
      ),
      key: 'startMethod',
      className: 'hideBorderBottom',
    },
    ...(currentUser?.role === 'admin' && startMethod.toLowerCase() === 'rfid'
      ? [
          {
            property: rfidNumberText,
            value: rfidNumber,
            key: 'rfidNumber',
            className: 'hideBorderBottom',
          },
        ]
      : []),
    {
      property: tenantText,
      value: ellipsisValue(doesDisplayNameExist),
      key: 'tenant',
      className: 'hideBorderBottom',
    },
    ...((configProvider.config.network === 'itsElectric' && currentUser?.role === 'admin') ||
    configProvider.config.network !== 'itsElectric'
      ? [
          {
            property: 'Email',
            value: user && user.email ? ellipsisValue(user.email) : '-',
            key: 'email',
            className: 'hideBorderBottom',
          },
        ]
      : []),
    { property: listingTitleText, value: listing.title, key: 'listingTitle', className: 'hideBorderBottom' },
    { property: listingIDText, value: listing.id, key: 'listingID' },
  ]

  const timeDurationAndStateInfo = [
    {
      property: connectedText,
      value: <DateTimeV2 date={startAt} timezone={timezone} isSidePanel />,
      key: 'startAt',
      description: <br />,
      className: 'hideBorderBottom',
    },
    {
      property: disconnectedText,
      value: !endAt ? '-' : <DateTimeV2 date={endAt} timezone={timezone} isSidePanel />,
      key: 'endAt',
      description: !endAt ? null : <br />,
      className: 'hideBorderBottom',
    },
    {
      property: chargingStartText,
      value: !chargeStartAt ? '-' : <DateTimeV2 date={chargeStartAt} timezone={timezone} isSidePanel />,
      key: 'chargeStartAt',
      description: !chargeStartAt ? null : <br />,
      className: 'hideBorderBottom',
    },
    {
      property: chargingEndText,
      value: !chargeEndAt || chargeEndAt === null ? '-' : renderChargingStop(transaction),
      key: 'chargeEndAt',
      description: !chargeEndAt || chargeEndAt === null ? null : <br />,
      className: 'hideBorderBottom',
    },
    {
      property: chargingDurationText,
      value: !currentChargingDuration ? (
        '-'
      ) : (
        <AlignedContentRight children={formatDurationWithTwoDigits(currentChargingDuration)} />
      ),
      key: 'currentChargingDuration',
      className: 'hideBorderBottom',
    },
    {
      property: totalEnergyText,
      key: 'energy',
      value: !energy ? '-' : <AlignedContentRight children={`${formatEnergy(energy / 1000)} kWh`} />,
      className: 'hideBorderBottom',
    },
    { property: stateText, key: 'state', value: <TransactionStateBadge transaction={transaction} /> },
  ]

  const chargerInfo = [
    {
      property: vendorText,
      key: 'vendor',
      value: charger.chargePointVendor,
      className: 'hideBorderBottom',
    },
    {
      property: SNText,
      key: 'serialNumber',
      value: charger.chargePointSerialNumber,
      className: 'hideBorderBottom',
    },
    {
      property: chargerTypeText,
      key: 'chargerType',
      value: charger.chargingType,
      className: 'hideBorderBottom',
    },
    {
      property: connectorTypeText,
      key: 'connectorType',
      value: connector.connectorType,
      className: 'hideBorderBottom',
    },
    { property: connectorIdText, key: 'connectorId', value: `#${connector.connectorId}` },
  ]

  const carInfo = [
    {
      property: brandText,
      key: 'make',
      value: car?.brand || '-',
      className: 'hideBorderBottom',
    },
    {
      property: `${modelText} & ${yearText}`,
      key: 'modelYear',
      value: car?.model && car.year ? `${car?.model} ${car?.year}` : '-',
      className: 'hideBorderBottom',
    },
    {
      property: socBeforeChargingText,
      key: 'socBeforeCharging',
      value: typeof percentStart === 'number' ? `${percentStart}%` : '-',
      className: 'hideBorderBottom',
    },
    { property: socAfterChargingText, key: 'socAfterCharging', value: percent === 0 ? '-' : `${percent}%` },
  ]

  const data = [...listingInfo, ...timeDurationAndStateInfo, ...chargerInfo, ...carInfo] as ListProps[]

  return (
    <>
      {data.map((t) => (
        <List.Item actions={[t.value]} className={t.className} key={`${transaction.id}-${t.key}`}>
          <List.Item.Meta title={t.property} description={t?.description} />
        </List.Item>
      ))}
    </>
  )
}

export default TransactionPanelItem
