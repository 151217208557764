import React, { useEffect, useState } from 'react'
import { Redirect, useHistory } from 'react-router'
import { useLocation } from 'react-router-dom'
import { withAuthenticatedLayout } from '../../components/layouts/layout'
import { useAppState } from '../../state'
import { Button, Col, Result, Row, Spin } from 'antd'
import { GetSelf } from '../../services/data-provider/auth'
import { SwtchError } from '../../models/error'
import { Box } from '../../atom/box'
import { LoadingOutlined, LockOutlined } from '@ant-design/icons'
import { Links } from '../../components/routes/paths'
import { checkDriverAccess, getTenantPermissions } from '../../models/user'
import queryString from 'query-string'
import { log } from '../../logger'
import { getSession } from '../../state/session-store'

export const TenantSelectBasePage: React.FC = () => {
  const history = useHistory()
  const location = useLocation()
  const { redirect_path, ...rest } = queryString.parse(location.search)
  const {
    currentUser,
    setCurrentUser,
    hasNoAccess,
    setHasNoAccess,
    selectedTenant,
    selectTenant,
    logout,
  } = useAppState()
  const [error, setError] = useState<SwtchError>()
  const session = getSession()
  const currentDate = Math.floor(Date.now() / 1000)

  useEffect(() => {
    if (currentUser) {
      return
    }
    log('onboard page is loading, current user is not defined so attempting to retrieve it')
    GetSelf()
      .then((user) => {
        log('found signed in user', user)
        setCurrentUser(user)
      })
      .catch((err) => {
        if ((err?.cause as any)?.status === 400) {
          setHasNoAccess(true)
        } else {
          setError(err)
        }
      })
  }, [currentUser])

  if (error && session?.expiresAt && currentDate > session.expiresAt) {
    return (
      <>
        <Result
          status="500"
          icon={<LoadingOutlined />}
          title="Session Expired"
          subTitle={`Redirecting back to the login page!`}
        />
        <Spin size="large">
          {log('logging out the user and redirecting to the auth login page')}
          {setTimeout(() => {
            logout()
            history.push(Links.auth0login())
          }, 5000)}
        </Spin>
      </>
    )
  }
  if (hasNoAccess) {
    return (
      <Result
        icon={<LockOutlined />}
        title="You do not have access to a Tenant"
        subTitle="Please contact an administrator"
      />
    )
  }
  if (error) {
    return (
      <Result
        status="500"
        title="Oops something went wrong"
        subTitle={`${error.description}. Try again.`}
        extra={
          <Button
            type="primary"
            onClick={() => {
              logout()
              history.push(Links.auth0login())
            }}
          >
            Back to login
          </Button>
        }
      />
    )
  }

  if (!currentUser) {
    return (
      <Row>
        <Col span={24}>
          <Box>
            <Result icon={<LoadingOutlined />} title="Loading your profile" />
          </Box>
        </Col>
      </Row>
    )
  }

  let redirectUri = Links.dashboard()
  if (typeof redirect_path === 'string') {
    redirectUri = `${redirect_path}${redirect_path.includes('?') ? '&' : '?'}${queryString.stringify(rest)}` as string
  }

  if (currentUser.role !== 'user') {
    // selectTenant(undefined)
    return <Redirect to={redirectUri} />
  }

  if (selectedTenant) {
    return <Redirect to={redirectUri} />
  }

  const tenants = getTenantPermissions(currentUser)
  const canDriverAccess = currentUser?.role === 'user' && checkDriverAccess(currentUser?.accesses)
  if (tenants.length === 0 || !canDriverAccess) {
    return (
      <Result
        icon={<LockOutlined />}
        title="You do not have access to a Tenant"
        subTitle="Please contact an administrator"
      />
    )
  }

  if (tenants.length === 1) {
    selectTenant(tenants[0])
    return <Redirect to={redirectUri} />
  }

  selectTenant(undefined)
  return <Redirect to={redirectUri} />
}

export const TenantSelectPage = withAuthenticatedLayout(TenantSelectBasePage)
